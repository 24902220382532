import queryString from 'query-string'

import { QueryParams } from '@customTypes/Generic'

export const allowedAttributes = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'vtp_cid',
  'vtp_agid',
  'vtp_mt',
  'vtp_adid',
  'vtp_nw',
  'vtp_pos',
  'vtp_loc',
  'vtp_dev',
  'vtp_devmod',
  'vtp_pl',
  'vtp_tg',
  'vtp_lp',
  'gclid',
  'utm_expid',
]

const keepOnlyAllowedAttributes = (originalObject: QueryParams): QueryParams => {
  const entries = Object.entries(originalObject)
  const allowedEntries = entries.filter(([key]) => allowedAttributes.includes(key))
  return Object.fromEntries(allowedEntries)
}

const getTrackingParams = (windowLocation: { search: string }): QueryParams => {
  const paramsAsObject = queryString.parse(windowLocation.search)
  const result = keepOnlyAllowedAttributes(paramsAsObject)
  return result
}

export default getTrackingParams
