import { InsuranceTypeOptions } from 'src/config/insuranceTypeOptions'
import { PatientTypeOptions } from 'src/config/patientTypeOptions'

export type QueryParams = Record<string, string | string[] | null | undefined>

export enum AppMode {
  NORMAL = 'NORMAL',
  RECALL = 'RECALL',
  CANCEL = 'CANCEL',
  GAP_FILLER = 'GAP_FILLER',
  SURVEY = 'SURVEY',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  DIVERSE = 'DIVERSE',
}

export enum API_ERROR_CODES {
  BOOKING_SLOT_GONE = 'BOOKING_SLOT_GONE',
  INVALID_REQUEST_PARAM_VALUE = 'INVALID_REQUEST_PARAM_VALUE',
  // When a user tries to reshedule a booking that has already been resheduled
  DUPLICATE_RESCHEDULE_BOOKING = 'DUPLICATE_RESCHEDULE_BOOKING',
}

export enum SlotStatus {
  NOT_CHECKED = 'NOT_CHECKED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface RegularFlowQueryParams {
  treatmentBundleReferenceId?: string
  patientType?: PatientTypeOptions
  patientReferenceId?: string
  practitionerReferenceIds?: string
  phoneNr?: string
  insuranceType?: InsuranceTypeOptions
}
