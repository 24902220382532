import { AppMode } from '@customTypes/Generic'
import { CalendarType } from 'src/config/calendarEvents'
import { AnalyticsAction, AnalyticsScreen, Event } from '@customTypes/Analytics'
import { AvailyEvent } from '@customTypes/Availy'

import { postParentMsg } from '../utils/postMessage'

const EVENT_NAME = 'D21TrackEvent'

const formatLabel = (label: string) =>
  label
    .toLowerCase()
    .replace(/[^a-zA-ZöäüÖÄÜß ]/g, '')
    .trim()
    .replace(/\s{1,}/g, '_')

const queueEvent = (event: Event) => {
  const dataLayer = window.dataLayer || []
  const availyDataLayer = window.availyDataLayer || []
  dataLayer.push(event)
  availyDataLayer.push(event)
}

const queueVisitedEvent = (screen: AnalyticsScreen, action: AnalyticsAction) => {
  queueEvent({
    event: EVENT_NAME,
    category: 'form',
    label: screen,
    action,
  })
}

export const dispatchTrackingEvent = (screen: AnalyticsScreen, action: AnalyticsAction): void => {
  queueVisitedEvent(screen, action)

  if (window.availyEmbedded) {
    postParentMsg({
      type: AvailyEvent.GTM_EVENT_EMMITED,
      payload: {
        screen,
        action,
      },
    })
  }
}

export const dispatchAnalyticsEvent = (
  action: AnalyticsAction,
  label: string,
  category: Event['category'],
  payload?: Event['payload']
): void => {
  queueEvent({
    event: EVENT_NAME,
    category,
    label,
    action,
    payload,
  })

  if (window.availyEmbedded) {
    postParentMsg({
      type: AvailyEvent.GTM_EVENT_EMMITED,
      category,
      payload: { ...payload, label, action },
    })
  }
}

export const seenTreatment = (): void => dispatchTrackingEvent(AnalyticsScreen.treatment, AnalyticsAction.seen)
export const completedTreatment = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.treatment, AnalyticsAction.completed)

export const seenPatientType = (): void => dispatchTrackingEvent(AnalyticsScreen.patientType, AnalyticsAction.seen)
export const completedPatientType = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.patientType, AnalyticsAction.completed)

export const seenBookingFor = (): void => dispatchTrackingEvent(AnalyticsScreen.bookingFor, AnalyticsAction.seen)
export const completedBookingFor = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.bookingFor, AnalyticsAction.completed)

export const seenPractitioner = (): void => dispatchTrackingEvent(AnalyticsScreen.practitioner, AnalyticsAction.seen)
export const completedPractitioner = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.practitioner, AnalyticsAction.completed)

export const seenAppointment = (): void => dispatchTrackingEvent(AnalyticsScreen.appointment, AnalyticsAction.seen)
export const completedAppointment = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.appointment, AnalyticsAction.completed)

export const seenContactForm = (): void => dispatchTrackingEvent(AnalyticsScreen.contactForm, AnalyticsAction.seen)
export const completedContactForm = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.contactForm, AnalyticsAction.completed)

export const seenArrivalTime = (): void => dispatchTrackingEvent(AnalyticsScreen.arrivalTime, AnalyticsAction.seen)
export const completedArrivalTime = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.arrivalTime, AnalyticsAction.completed)

export const seenInsurance = (): void => dispatchTrackingEvent(AnalyticsScreen.insurance, AnalyticsAction.seen)
export const completedInsurance = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.insurance, AnalyticsAction.completed)

export const seenAppointmentConfirmation = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.appointmentConfirmation, AnalyticsAction.seen)
export const completedRecallFlow = (): void =>
  dispatchTrackingEvent(AnalyticsScreen.appointmentConfirmation, AnalyticsAction.completed)

export const selectedInsuranceHolderContact = (): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, 'insurance-holder-contact', 'form')

export const selectedInsuranceHolderPatient = (): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, 'insurance-holder-patient', 'form')

export const appointmentTaken = (): void =>
  dispatchAnalyticsEvent(AnalyticsAction.appointment_already_taken, 'error', 'availy')

export const analyticsEventModalExit = (): void => dispatchAnalyticsEvent(AnalyticsAction.click, 'modal_exit', 'form')

export const analyticsEventSelectedTreatment = (treatment: string): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, `treatmentType_${formatLabel(treatment)}`, 'form')

export const analyticsEventCallClinic = (phone: string): void =>
  dispatchAnalyticsEvent(AnalyticsAction.click, phone, 'form')

export const analyticsEventSelectedPractitioner = (practitionerName: string): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, `practitionerType_${formatLabel(practitionerName)}`, 'form')

export const analyticsEventSelectedPatient = (isPatient: boolean): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, `patientType_${isPatient ? 'yes' : 'no'}`, 'form')

export const analyticsEventSelectedInsurance = (insuranceType: string): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, `insuranceType_${insuranceType}`, 'form')

export const analyticsEventSelectedAppointment = (appointment: string): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, `appointmentType_${appointment}`, 'form')

export const analyticsEventSelectedShortTermAppointmentStep = (isInterested: boolean): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, `shortTermType_${isInterested ? 'yes' : 'no'}`, 'form')

export const analyticsEventSelectedMinutesToPracticeStep = (minutes: string): void =>
  dispatchAnalyticsEvent(AnalyticsAction.select, `minutesToPracticeType_${minutes}`, 'form')

export const analyticsEventGapFillerFailed = (payload?: Event['payload']): void =>
  dispatchAnalyticsEvent(AnalyticsAction.gap_booking_attempt, 'gap-already-taken-error', AppMode.GAP_FILLER, payload)

export const analyticsEventGapFillerSucceed = (payload?: Event['payload']): void =>
  dispatchAnalyticsEvent(AnalyticsAction.gap_booking_attempt, 'gap-successfully-filled', AppMode.GAP_FILLER, payload)

export const analyticsEventGapFillerPastDate = (payload?: Event['payload']): void =>
  dispatchAnalyticsEvent(AnalyticsAction.gap_booking_attempt, 'appointment-in-past-error', AppMode.GAP_FILLER, payload)

export const analyticsEventRescheduleMissingParam = (payload?: Event['payload']): void =>
  dispatchAnalyticsEvent(AnalyticsAction.seen, 'appointment-confirmation-missing-param', 'availy', payload)

export const analyticsEventAddAppointmentToCalendar = (calendar: CalendarType, category: AppMode): void => {
  const calendarToLabel: Record<CalendarType, string> = {
    google: 'google-calendar',
    yahoo: 'yahoo-calendar',
    apple: 'apple-calendar',
    outlook: 'outlook-calendar',
    ical: 'ical',
  }

  dispatchAnalyticsEvent(AnalyticsAction.add_appointment_to_calendar, calendarToLabel[calendar], category)
}
