import { Event } from '@customTypes/Availy'

export const postParentMsg = (msg: Event): void => {
  if (window.parent) {
    window.parent.postMessage(msg, '*')
  }
}

export const postAvailyMsg = (msg: Event): void => {
  const availyIframe = document.getElementById('availy-iframe') as HTMLIFrameElement

  if (availyIframe) {
    availyIframe.contentWindow?.postMessage(msg, '*')
  }
}
