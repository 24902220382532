import { AvailyEvent } from '@customTypes/Availy'
import { Environment } from '../types'
import { OpenAvailyArgs } from '../types/OpenAvailyArgs'

export const openAvailyModal = (openAvailyArgs?: OpenAvailyArgs) => {
  window.postMessage({ type: AvailyEvent.OPEN_MODAL, payload: openAvailyArgs })
}

export const setEnvironment = (env: Environment) => {
  const currentWindowEnv = window.env || {}
  window.env = { ...currentWindowEnv, REACT_APP_ENV: env }
}

export const getAvailyBeforeGtm = () => `window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }

        // set „denied" as default for both ad and analytics storage, as well as ad_user_data and ad_personalization,
        gtag("consent", "default", {
            ad_user_data: "denied",
            ad_personalization: "denied",
            ad_storage: "denied",
            analytics_storage: "denied",
            wait_for_update: 2000 // milliseconds to wait for update
        });

        // Enable ads data redaction by default [optional]
        gtag("set", "ads_data_redaction", true);`

export const getAvailyGTMScriptContent = () => `(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'availyDataLayer', 'GTM-NFVV6BM');`
