import { Event } from '@customTypes/Availy'

const listenMessages = (callback: (data: Event) => void): void => {
  const eventMethod = (window.addEventListener as any) ? 'addEventListener' : 'attachEvent'
  const eventer = (window as any)[eventMethod]
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

  eventer(
    messageEvent,
    (event: Record<string, any>) => {
      const key = event.message ? 'message' : 'data'
      const data = event[key]
      callback(data)
    },
    false
  )
}

export default listenMessages
