const toJSON = (data: Record<string, any>): string => {
  try {
    const result = JSON.stringify(data)

    return result
  } catch (e) {
    console.error(e)
    return ''
  }
}

export default toJSON
